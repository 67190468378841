import {
    IconButton,
    CardMedia,
    Typography,
    Box,
    Stack,
    Popover
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { memo, useState } from 'react'
import { CardSubHeader, CardText, StyledCard, StyledCardActions, StyledCardContent, StyledCardHeader } from './EventCard.styled'
import useEventTimeDifference from '../customHooks/useEventTimeDiff'
import { useTranslation } from 'react-i18next'
import { namespace } from '../localization'
import { getMajorCategoryIcon } from '../common/icons/majorCategoryIcons'
import ReactCountryFlag from 'react-country-flag'
import { getSubCategoryIcon } from '../common/icons/subCategoryIcons'

const DEFUALT_IMG_URL = "https://www.project-drives.eu/Content/Default/Images/IMAGENS_HOMEPAGE/imageDefault.png"

const ReadMore = ({ children }) => {
    const [isReadMore, setIsReadMore] = useState(true)

    return (
        <CardText>
            {isReadMore ? children.slice(0, 55) : children}
            <span style={{ cursor: 'pointer', fontWeight: 800 }} onClick={() => setIsReadMore(!isReadMore)}>
                {isReadMore ? '...read more' : ' show less'}
            </span>
        </CardText>
    )
}

const EventCard = (props) => {
    const { onClose, eventData, anchorEl, appData } = props
    const [timeDifferences, setTimeDifferences] = useState({})
    const { t } = useTranslation(namespace)

    //hook to calculate the time difference show onto the card
    useEventTimeDifference(appData, setTimeDifferences)

    if (!eventData) return

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
        >
            <StyledCard >
                <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <StyledCardHeader
                        title={
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                                <ReactCountryFlag countryCode={eventData.country.country_code} svg style={{ width: '1.4em', height: '1.4em' }} />
                                <span style={{fontSize: 'medium'}}>{eventData.country.country}</span>
                            </Box>
                        }
                        style={{ fontSize: '20px', fontWeight: 600, padding: '8px 16px' }}
                        subheader={
                            <Stack paddingLeft={1} spacing={0.5}>
                                <Box display={'flex'} alignItems={'center'} gap={0.5}>
                                    <span className='drawer-main-icon'>{getMajorCategoryIcon(eventData.security_type)}</span>
                                    <CardSubHeader>
                                        {eventData.security_type}
                                    </CardSubHeader>
                                </Box>
                                <span className='drawer-main-icon'>{getSubCategoryIcon(eventData.incident_types)}</span>
                            </Stack>
                        }
                    />
                    <Stack direction={'column'} alignItems={'end'} padding={1} spacing={1}>
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={{ color: '#067EF0' }} />
                        </IconButton>
                        <Typography sx={{
                            fontFamily: 'Open Sans, sans-serif',
                            fontSize: '15px',
                            fontWeight: 500,
                            lineHeight: '1.6em',
                            letterSpacing: '0.6px'
                        }}>
                            {timeDifferences[eventData.id] || ""}
                        </Typography>
                    </Stack>
                </Box>
                <CardMedia
                    component={'img'}
                    height={160}
                    image={eventData.media || DEFUALT_IMG_URL}
                    onError={(e) => {
                        e.currentTarget.src = DEFUALT_IMG_URL
                    }}
                    alt={t('imageNotAvialable')}
                />
                <StyledCardContent>
                    {eventData?.remarks?.length > 55 ? (
                        <ReadMore>
                            {eventData?.remarks}
                        </ReadMore>
                    ) : (
                        <CardText>
                            {eventData?.remarks}
                        </CardText>
                    )}
                </StyledCardContent>
                <StyledCardActions>
                    {eventData?.source?.map((source, index) => (
                        <a
                            href={source}
                            target='_blank'
                            rel="noopener noreferrer"
                            style={{ color: '#ffffff', fontWeight: 800 }}
                            key={index}
                        >
                            {`source${index + 1}`}
                        </a>
                    ))}
                </StyledCardActions>
            </StyledCard>
        </Popover>
    )
}

export default memo(EventCard)