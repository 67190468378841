import { Box, styled, SwipeableDrawer, Button, Chip } from '@mui/material'

export const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        background: '#000000',
        width: '25%',
        boxShadow: '0px 0px 10px #ffffff',
        minWidth: '16em'
    }
}))

export const EventHeader = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1),
    textAlign: 'center',
    borderBottom: '1px solid rgba(141 135 135)'
}))
export const DrawerContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}))

export const DrawerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}))

export const ListButton = styled(Button)`
    text-transform: capitalize;
    text-align: left;
    justify-content: flex-start;
    width: 92%;
    border-bottom: 1px solid rgba(141 135 135);
    padding-left: 0;
    padding-bottom: 16px;
`

export const StyledChip = styled(Chip)(({ theme }) => ({
    background: '#ffffff',
    color: '#000000',
    cursor: 'pointer',
    width: '48%',
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    '&.MuiChip-filled': {
        backgroundColor: '#6FDCE9',
        color: '#ffffff',
        '&:hover .MuiSvgIcon-root': {
            color: '#067EF0'
        }
    },
    '&.MuiChip-outlined': {
        borderColor: '#067EF0',
        '&:hover': {
            borderColor: '#6FDCE9',
            background: "#ffffff"
        }
    }
}))

export const ResetFilter = styled(Chip)(({ theme }) => ({
    background: '#ffffff',
    color: '#000000',
    cursor: 'pointer',
    width: '48%',
    border: '1px solid #067EF0',
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    '&:hover': {
        backgroundColor: '#ffffff',
        color: '#000000',
        border: '1px solid #6FDCE9'
    },
}))

export const FlexListItem = styled(Box)`
    display: flex;
    gap: 1em;
    align-items: center;
    flex-wrap: wrap;
`