import { useTranslation } from 'react-i18next'
import { namespace } from '../localization'
import { Typography, IconButton, Stack, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DrawerContainer, DrawerHeader, EventHeader, FlexListItem, ListButton, ResetFilter, StyledChip, StyledDrawer } from './LatestEvents.styled'
import { useMap } from '../../context/MapContext'
import '../../index.css'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { memo, useCallback, useEffect, useState } from 'react'
import EventCard from '../eventCard/EventCard'
import useEventTimeDifference from '../customHooks/useEventTimeDiff'
import { getMajorCategoryIcon } from '../common/icons/majorCategoryIcons'
import ReactCountryFlag from 'react-country-flag'
import { getSubCategoryIcon } from '../common/icons/subCategoryIcons'

const LatestEvents = (props) => {
    const { isDrawerOpen, closeDrawer, setOpenDrawer } = props
    const { t } = useTranslation(namespace)
    const { map, appData } = useMap()
    const [eventsList, setEventsList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedFilters, setSelectedFilters] = useState([])
    const [timeDifferences, setTimeDifferences] = useState({})

    //hook to calculate and update the event time difference
    useEventTimeDifference(appData, setTimeDifferences)

    useEffect(() => {
        setEventsList(appData)
    }, [appData])

    const handleEventClick = useCallback((event) => {
        let element = null
        const { coordinates } = event.coordinates

        //logic to redirect to the event coordinates on the fly
        map?.current?.flyTo({
            center: event.coordinates.coordinates ?? [0, 0],
            zoom: event.coordinates.zoom ?? 10,
            essential: true,
            curve: 1,
            speed: 10,
        })

        //logic to find the marker available at event coordinates
        map?.current?._markers?.forEach(marker => {
            const { lng, lat } = marker._lngLat
            if (lng == coordinates[0] && lat == coordinates[1]) {    //eslint-disable-line
                element = marker._element
            }
        })

        //set the marker as anchor element when fly effect ends
        const moveEndListener = () => {
            setAnchorEl(element)
            map?.current?.off('moveend', moveEndListener)
        }

        map?.current?.on('moveend', moveEndListener)

        closeDrawer()
        setSelectedEvent(event)
    }, [map, closeDrawer])

    const handleFilterEvent = (currentEvent) => {
        if (!selectedFilters.includes(currentEvent)) {
            setSelectedFilters(prevFilters => [...prevFilters, currentEvent])
        } else {
            setSelectedFilters(prevFilters => prevFilters.filter(item => item !== currentEvent))
        }
    }

    useEffect(() => {
        if (!selectedFilters.length) {
            setEventsList(appData)
        } else {
            setEventsList(appData.filter(event => selectedFilters.includes(event.security_type.toLowerCase())))
        }
    }, [selectedFilters, appData])

    const handelCloseEventCard = useCallback(() => {
        setAnchorEl(null)
        setOpenDrawer(true)
    }, [setOpenDrawer])

    const handleResetFilter = useCallback(() => {
        setEventsList(appData ?? [])
        setSelectedFilters([])
    }, [appData])

    return (
        <>
            <StyledDrawer
                anchor='right'
                open={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={() => { }}
                variant='persistent'
            >
                <DrawerContainer>
                    <DrawerHeader>
                        <EventHeader>
                            <Typography variant='h4'>
                                {t('latestEvent')}
                            </Typography>
                        </EventHeader>
                        <IconButton onClick={closeDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </DrawerHeader>
                    <Stack direction={'column'} justifyContent={'space-between'} height={'90vh'}>
                        <Stack sx={{ height: '90%', overflowY: 'auto' }}>
                            {eventsList?.map(event => (
                                <ListButton key={event.id} onClick={() => handleEventClick(event)}>
                                    <Stack direction={'column'} padding={0.5} spacing={1.5} paddingLeft={0}>
                                        <FlexListItem>
                                            <span className='drawer-main-icon'>{getMajorCategoryIcon(event.security_type)}</span>
                                            <span className='drawer-main-icon drawer-sub-icon'>{getSubCategoryIcon(event.incident_types)}</span>
                                            <Typography variant='h6'>
                                                {timeDifferences[event.id] || ""}
                                            </Typography>
                                            <Box display={'flex'} alignItems={'center'} gap={0.5}>
                                                <ReactCountryFlag countryCode={event.country.country_code} svg style={{ width: '1.8em', height: '1.8em' }} />
                                                <Typography variant='h6'>
                                                    {event.country.country || ""}
                                                </Typography>
                                            </Box>
                                        </FlexListItem>
                                        <Typography variant='body1' style={{ margin: 0 }}>
                                            {event.meaning}
                                        </Typography>
                                    </Stack>
                                </ListButton>
                            ))}
                        </Stack>
                        <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                            <StyledChip
                                sx={{ padding: 0 }}
                                label={t('aviationSecurity')}
                                icon={<span className='filter-icon-container'>{getMajorCategoryIcon('aviation')}</span>}
                                onClick={() => handleFilterEvent('aviation')}
                                variant={selectedFilters.includes('aviation') ? "filled" : "outlined"}
                            />
                            <StyledChip
                                label={t('travelSecurity')}
                                icon={<span className='filter-icon-container'>{getMajorCategoryIcon('travel')}</span>}
                                onClick={() => handleFilterEvent('travel')}
                                variant={selectedFilters.includes('travel') ? "filled" : "outlined"}
                            />
                            <StyledChip
                                label={t('geopolitical')}
                                icon={<span className='filter-icon-container'>{getMajorCategoryIcon('geopolitical')}</span>}
                                onClick={() => handleFilterEvent('geopolitical')}
                                variant={selectedFilters.includes('geopolitical') ? "filled" : "outlined"}
                                sx={{ width: '31%' }}
                            />
                            <StyledChip
                                label={t('defense')}
                                icon={<span className='filter-icon-container'>{getMajorCategoryIcon('defense')}</span>}
                                onClick={() => handleFilterEvent('defense')}
                                variant={selectedFilters.includes('defense') ? "filled" : "outlined"}
                                sx={{ width: '31%' }}
                            />
                            <ResetFilter
                                label={t('reset')}
                                icon={<AutorenewIcon />}
                                onClick={handleResetFilter}
                                sx={{ width: '31%' }}
                            />
                        </Box>
                    </Stack>
                </DrawerContainer>
            </StyledDrawer>
            <EventCard
                anchorEl={anchorEl}
                onClose={handelCloseEventCard}
                eventData={selectedEvent}
                appData={appData}
            />
        </>
    )
}

export default memo(LatestEvents)