import {styled,Box,IconButton} from '@mui/material'

export const Container = styled(Box)(({theme}) => ({
    padding: theme.spacing(3,5),
    height: '90vh',
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1,3)
    }
}))
export const StyledIconBtn = styled(IconButton)(({theme}) => ({
    backgroundColor: theme.palette.text.primary,
    transition: 'transform 0.3 ease',
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: theme.palette.text.primary
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
        color: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.25rem'
        }
    }
}))

export const IconContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
`