import { useCallback, useState } from 'react'
import EventCard from '../../../components/eventCard/EventCard'
import { getMajorCategoryIcon } from '../../../components/common/icons/majorCategoryIcons'

const Marker = (props) => {
    const { event, setOpenDrawer, appData } = props
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMarkerClick = useCallback((event) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
        setOpenDrawer(true)
    }, [setOpenDrawer])

    return (
        <>
            <span onClick={handleMarkerClick} className='map-icon'>
                {getMajorCategoryIcon(event.security_type)}
            </span>
            <EventCard
                anchorEl={anchorEl}
                eventData={event}
                onClose={handleClose}
                appData={appData}
            />
        </>
    )
}

export default Marker