import { createTheme } from '@mui/material/styles'


export const theme = createTheme({
    palette: {
        primary: {
            main: '#067EF0',
            light: '#6FDCE9'
        },

        text: {
            primary: '#ffffff',
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.text.primary,
                }),
                h3: {
                    fontSize: '45px',
                    fontWeight: 700,
                    lineHeight: '54.59px'
                },
                h4: {
                    fontSize: '22px',
                    fontWeight: 600,
                },
                h5: {
                    fontSize: '20px',
                    fontWeight: 600,
                },
                h6: {
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: 0,
                },
                body1: {
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '1.6em',
                    letterSpacing: '0.6px'
                },
                body2: {
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing:'0.5px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: theme.palette.primary.light
                    }
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                icon: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: theme.palette.primary.light
                    }
                })
            }
        }
    },
    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(',')       /*TODO: need to confirm with UI/UX team */
    }
})