import { useEffect } from "react";
import DateUtils from "../../utils/DateUtils";

const useEventTimeDifference = (appData, setTimeDifferences) => {
    useEffect(() => {
        // Initial calculation
        appData?.forEach(event => {
            const initialTimeDifference = DateUtils.getTimeDifference(event.date, event.time)
            setTimeDifferences(prev => ({ ...prev, [event.id]: initialTimeDifference }))
        })
    
        // Update every minute
        let intervalTime = 60 - new Date().getSeconds()
        const intervalId = setInterval(() => {
            appData?.forEach(event => {
                const updateTimeDifference = DateUtils.getTimeDifference(event.date, event.time)
                setTimeDifferences(prev => ({ ...prev, [event.id]: updateTimeDifference }))
            })
            intervalTime = 60
        }, intervalTime * 1000)
    
        return () => {
            clearInterval(intervalId)
        }
    }, [appData,setTimeDifferences])
    
};

export default useEventTimeDifference;
