import LandingPageContainer from "./containers/landingPageContainer/LandingPageContainer"
import { ThemeProvider } from '@mui/system'
import { theme } from "./theme/Theme"
import { initLocalization } from './config/localization/i18'
import MapContainer from "./map/containers/mapContainer/MapContainer"
import { MapProvider } from "./context/MapContext"


initLocalization('en')
function App() {
  return (
    <ThemeProvider theme={theme}>
      <MapProvider>
        <MapContainer />
        <LandingPageContainer />
      </MapProvider>
    </ThemeProvider>
  )
}

export default App;
