import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as modulesLocalization from './Localization'

i18n.use(initReactI18next).init({
    lng: 'en',
})

export const initLocalization = (language) => {
    const modulesLocalizations = Object.values(modulesLocalization)

    const localizations = modulesLocalizations.reduce((acc, localization) => {
        acc[localization.namespace] = localization[language]
        return acc
    }, {})

    Object.keys(localizations).forEach(key => {
        i18n.addResourceBundle(language, key, localizations[key], true, true)
    })
}

export default i18n