import airspaceAd from '../../../assets/subCategoryIcons/airspaceAd.png'
import airspaceAirstrike from '../../../assets/subCategoryIcons/airspaceAirstrike.png'
import airspaceDrone from '../../../assets/subCategoryIcons/airspaceDrone.png'
import airspaceMilitaryAc from '../../../assets/subCategoryIcons/airspaceMilitaryAc.png'
import airspaceMilitaryEx from '../../../assets/subCategoryIcons/airspaceMilitaryEx.png'
import airspaceMissile from '../../../assets/subCategoryIcons/airspaceMissile.png'
import airspaceMortar from '../../../assets/subCategoryIcons/airspaceMortar.png'
import airspaceNavyMovt from '../../../assets/subCategoryIcons/airspaceNavyMovt.png'
import airspaceNotam from '../../../assets/subCategoryIcons/airspaceNotam.png'

import landmarkActiveShooting from '../../../assets/subCategoryIcons/landmarkActiveShooting.png'
import landmarkArmedRobbery from '../../../assets/subCategoryIcons/landmarkArmedRobbery.png'
import landmarkBomb from '../../../assets/subCategoryIcons/landmarkBomb.png'
import landmarkCtOps from '../../../assets/subCategoryIcons/landmarkCtOps.png'
import landmarkKidnapping from '../../../assets/subCategoryIcons/landmarkKidnapping.png'
import landmarkIed from '../../../assets/subCategoryIcons/landmarkIed.png'
import landmarkSocialUnrest from '../../../assets/subCategoryIcons/landmarkSocialUnrest.png'
import landmarkStabbing from '../../../assets/subCategoryIcons/landmarkStabbing.png'
import roadClosure from '../../../assets/subCategoryIcons/roadClosure.png'

import militaryAid from '../../../assets/subCategoryIcons/militaryAid.png'
import militaryContract from '../../../assets/subCategoryIcons/militaryContract.png'
import militaryResearch from '../../../assets/subCategoryIcons/militaryResearch.png'
import militaryTechnology from '../../../assets/subCategoryIcons/militaryTechnology.png'
import weaponStash from '../../../assets/subCategoryIcons/weaponStash.png'
import weaponTrafficking from '../../../assets/subCategoryIcons/weaponTrafficking.png'

import conference from '../../../assets/subCategoryIcons/conference.png'
import elections from '../../../assets/subCategoryIcons/elections.png'
import meeting from '../../../assets/subCategoryIcons/meeting.png'
import peaceAgreement from '../../../assets/subCategoryIcons/peaceAgreement.png'
import pressRelease from '../../../assets/subCategoryIcons/pressRelease.png'
import sanction from '../../../assets/subCategoryIcons/sanction.png'
import speech from '../../../assets/subCategoryIcons/speech.png'
import tradeAgreement from '../../../assets/subCategoryIcons/tradeAgreement.png'


const renderIcon = (icon) => {
    return <img className='custom-icons' src={icon} alt='icon not found'/>
}
export const getSubCategoryIcon = (iconType) => {
    switch(iconType){
        case 'airspace_AD': 
            return renderIcon(airspaceAd)
        case 'airspace_Airstrike':
            return renderIcon(airspaceAirstrike)
        case 'airspace_Drone':
            return renderIcon(airspaceDrone)
        case 'airspace_Military_a/c':
            return renderIcon(airspaceMilitaryAc)
        case 'airspace_Military_ex':
            return  renderIcon(airspaceMilitaryEx)
        case 'airspace_Missile':
            return renderIcon(airspaceMissile)
        case 'airspace_Mortar':
            return renderIcon(airspaceMortar)
        case 'airspace_Navy_movt':
            return renderIcon(airspaceNavyMovt)
        case 'airspace_NOTAM':
            return renderIcon(airspaceNotam)
        case 'landmark_IED':
            return renderIcon(landmarkIed)
        case 'landmark_Active_shooting':
            return renderIcon(landmarkActiveShooting)
        case 'landmark_Kidnapping':
            return renderIcon(landmarkKidnapping)
        case 'landmark_Armed_robbery':
            return renderIcon(landmarkArmedRobbery)
        case 'landmark_Stabbing':
            return renderIcon(landmarkStabbing)
        case 'landmark_CT_ops':
            return renderIcon(landmarkCtOps)
        case 'landmark_Bomb_threat':
            return renderIcon(landmarkBomb)
        case 'landmark_Civil_unrest':
            return  renderIcon(landmarkSocialUnrest)
        case 'Military_aid':
            return renderIcon(militaryAid)
        case 'Military_contract':
            return renderIcon(militaryContract)
        case 'Military_research':
            return renderIcon(militaryResearch)
        case 'New_military_technology': 
            return renderIcon(militaryTechnology)
        case 'Peace_agreement':
            return renderIcon(peaceAgreement)
        case 'Trade_agreement': 
            return renderIcon(tradeAgreement)
        case 'Meeting': 
            return renderIcon(meeting)
        case 'Conference':
            return renderIcon(conference)
        case 'Speech':
            return renderIcon(speech)
        case 'Press_release':
            return renderIcon(pressRelease)
        case 'Election':
            return renderIcon(elections)
        case 'Sanction':
            return renderIcon(sanction)
        case 'Weapon_stash':
            return renderIcon(weaponStash)
        case 'Weapon_trafficking':
            return renderIcon(weaponTrafficking)
        case 'road_closure':
            return renderIcon(roadClosure)
        default :
            return <></>
    }
}