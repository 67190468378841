import { Card, styled, Dialog, CardContent, CardActions, Typography, CardHeader } from '@mui/material'

export const StyledCard = styled(Card)`
    width: 500px;
    background: #000000;
    color: #ffffff;
    box-shadow: 0px 0px 10px #ffffff;
    @media (max-width: 600px) {
        width: 360px;
    }
`

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        boxShadow: '1px 1px 10px #ffffff'
    }
}))

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(2, 2, 1, 2),
}))

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
    padding: theme.spacing(0, 2, 1, 2)
}))

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    '& .MuiCardHeader-title': {
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Open Sans, sans-serif',
    }
}))
export const CardSubHeader = styled(Typography)`
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.6px;
`

export const TimeStamp = styled(Typography)(({theme}) => ({
    '& .MuiTypography-body1': {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '1.6em',
        letterSpacing: '0.6px'
    }
}))

export const CardText = styled(Typography)`
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    line-height: 20px;
`