import { createContext, useContext } from "react"
import mapboxgl from '!mapbox-gl'   // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from 'react-dom/client'
import Marker from "../map/components/marker/Marker"
import axios from 'axios'
import { useTheme, useMediaQuery } from '@mui/material'

const MapContext = createContext()

mapboxgl.accessToken = 'pk.eyJ1IjoiYWxleHQyMyIsImEiOiJja3duc2Fkcmsyb3RnMnJub28wa3V2Z2c2In0.xZ2KYPsWGCXqD1mR9v-xsQ'
export const MapProvider = ({ children }) => {
    const mapContainer = useRef(null)
    const map = useRef(null)
    const [appData, setAppData] = useState([])
    const [viewPort, setViewPort] = useState({
        latitude: 39.8309,
        longitude: 27.3167,
        zoom: 3.14
    })

    const [openDrawer, setOpenDrawer] = useState(true)

    const theme = useTheme()
    const isExtraSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('lg', 'xl'))


    const fetchData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/app/travsec/`)
            setAppData(data)

        } catch (error) {
            console.error(error)
            alert("Something went wrong, please try again later")
        }
    }

    useEffect(() => {
        if (map.current) {
            try {
                appData?.forEach((event) => {
                    const ref = React.createRef()
                    ref.current = document.createElement('div')
                    // Render a Marker Component on our new DOM node
                    createRoot(ref?.current).render(
                        <Marker event={event} setOpenDrawer={setOpenDrawer} appData={appData} />
                    )
                    // Create a Mapbox Marker at our new DOM node
                    new mapboxgl.Marker(ref?.current)
                        .setLngLat(event?.coordinates?.coordinates)
                        .addTo(map?.current)
                })
            } catch (error) {
                console.error(error)
            }
        }
    }, [appData])

    useEffect(() => {
        fetchData()

        //api will call after every 3 min to update the events
        const intervalId = setInterval(fetchData, 3 * 60 * 1000)

        return () => {
            clearInterval(intervalId)
        }
    }, [])      //eslint-disable-line

    useEffect(() => {
        //adjust the zoom size of the globe based on screen size
        if (map?.current) {
            if (isExtraSmall) {
                map?.current?.setZoom(1.9)
            } else
                if (isSmallScreen) {
                    map?.current?.setZoom(2.1)
                } else
                    if (isMediumScreen) {
                        map?.current?.setZoom(2.4)
                    } else
                        if (isLargeScreen) {
                            map?.current?.setZoom(2.8)
                        } else map?.current?.setZoom(3.14)
        }
    }, [isSmallScreen, isMediumScreen, isLargeScreen, isExtraSmall])


    useEffect(() => {
        if (!mapContainer.current) return
        if (map.current) return
        map.current = new mapboxgl.Map({
            container: mapContainer?.current,
            center: [viewPort.longitude, viewPort.latitude],
            zoom: viewPort.zoom,
            style: 'mapbox://styles/alext23/clu00osri00ll01qs3w541rlx',  //style url of mapbox studio
        })

        map?.current?.on('move', () => {
            setViewPort((prevViewport) => ({
                ...prevViewport,
                longitude: map?.current?.getCenter()?.lng?.toFixed(4),
                latitude: map?.current?.getCenter()?.lat?.toFixed(4),
                zoom: map?.current?.getZoom()?.toFixed(2),
            }))
        })

        const secondsPerRevolution = 240
        // Above zoom level 4, do not rotate.
        const maxSpinZoom = 4
        // Rotate at intermediate speeds between zoom levels 3 and 4.
        const slowSpinZoom = 3

        let userInteracting = false
        const spinEnabled = true

        function spinGlobe() {
            const zoom = map?.current?.getZoom()
            if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
                let distancePerSecond = 360 / secondsPerRevolution
                if (zoom > slowSpinZoom) {
                    // Slow spinning at higher zooms
                    const zoomDif =
                        (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom)
                    distancePerSecond *= zoomDif
                }
                const center = map?.current?.getCenter()
                center.lng -= distancePerSecond
                // Smoothly animate the map over one second.
                // When this animation is complete, it calls a 'moveend' event.
                map?.current?.easeTo({ center, duration: 1000, easing: (n) => n })
            }
        }

        // Pause spinning on interaction
        map?.current?.on('mousedown', () => {
            userInteracting = true
        });
        map?.current?.on('dragstart', () => {
            userInteracting = true
        });

        // When animation is complete, start spinning if there is no ongoing interaction
        map?.current?.on('moveend', () => {
            spinGlobe()
        })

        spinGlobe()

        //   Add navigation control (the +/- zoom buttons)
        map?.current?.addControl(new mapboxgl.NavigationControl(), 'bottom-right')

        return () => {
            // Cleanup the map instance
            map?.current?.remove()
            map.current = null
        }

    }, [])   //eslint-disable-line

    return (
        <MapContext.Provider value={{
            mapContainer,
            map,
            appData,
            openDrawer,
            setOpenDrawer,
        }}>
            {children}
        </MapContext.Provider>
    )
}
export const useMap = () => useContext(MapContext)