import aviationSecurityIcon from '../../../assets/icons/aviationSecurityIcon.png'
import travelSecurityIcon from '../../../assets/icons/travelSecurityIcon.png'
import geopoliticalIcon from '../../../assets/icons/geopoliticalIcon.png'
import defenseIcon from '../../../assets/icons/defenseIcon.png'

const renderIcon = (icon) => {
    return <img className='custom-icons' src={icon} alt='icon not found' />
}
export const getMajorCategoryIcon = (iconType) => {
    switch (iconType.toLowerCase()) {
        case 'aviation':
            return renderIcon(aviationSecurityIcon)
        case 'travel':
            return renderIcon(travelSecurityIcon)
        case 'geopolitical':
            return renderIcon(geopoliticalIcon)
        case 'defense':
            return renderIcon(defenseIcon)
        default:
            return <></>
    }
}