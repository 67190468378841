import { Container } from "../../components/common/Common.styled"
import Header from "../../components/header/Header"
import { LayoutConatiner } from "./LandingPageContainer.styled"


const LandingPageContainer = () => {
    return (
        <Container>
            <LayoutConatiner>
                <Header />
            </LayoutConatiner>
        </Container>
    )
}

export default LandingPageContainer