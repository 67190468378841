import { StyledHeader, LogoContainer, StyledBtn } from "./Header.styled"
import { Box} from '@mui/material'
import LatestEvents from "../latestEvents/LatestEvents"
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useCallback } from "react"
import appLogo from '../../assets/logo.png'
import { useMap } from "../../context/MapContext"

const Header = () => {
    const { openDrawer, setOpenDrawer } = useMap()

    const handleDrawerClose = useCallback(() => {
        setOpenDrawer(false)
    }, [setOpenDrawer])

    return (
        <Box>
            <StyledHeader>
                <LogoContainer>
                    <img src={appLogo} height={60} className="appLogo" alt="logo not available" />
                </LogoContainer>
                <Box>
                    <StyledBtn isDrawerOpen={openDrawer} onClick={() => setOpenDrawer(!openDrawer)}>
                        <MenuRoundedIcon />
                    </StyledBtn>
                </Box>
            </StyledHeader>
            <LatestEvents
                isDrawerOpen={openDrawer}
                closeDrawer={handleDrawerClose}
                setOpenDrawer={setOpenDrawer}
            />
        </Box>
    )
}

export default Header