import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'
class DateUtils {
    getTimeDifference(date, time) {
        const dateObj = new Date(`${date}T${time}`)

        let diff = differenceInDays(new Date(), dateObj)

        if (diff >= 1) {
            return `${Math.floor(diff)} days ago`
        } else {
            diff = differenceInHours(new Date(), dateObj)
            if (diff >= 1) {
                return `${Math.floor(diff)} hours ago`
            } else {
                diff = differenceInMinutes(new Date(), dateObj)
                if (diff >= 1) {
                    return `${Math.floor(diff)} mins ago`
                } else return "Just now"
            }
        }
    }
    updateTimeDifference(date, time, callback) {
        const intervalId = setInterval(() => {
            const timeDifference = this.getTimeDifference(date, time)
            callback(timeDifference)
        }, 60000)  // Update every minute

        return () => clearInterval(intervalId)
    }
}

const dateUtilInstance = new DateUtils()

export default dateUtilInstance