import { styled, Box, IconButton } from '@mui/material'

export const StyledBtn = styled(IconButton,{
    shouldForwardProp: (prop) => prop !== 'isDrawerOpen'
})(({theme,isDrawerOpen}) => ({
    backgroundColor: theme.palette.text.primary,
    position: 'absolute',
    transition: theme.transitions.create(['right'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    right: isDrawerOpen? `calc(${(window.innerWidth * 0.25) + 'px'})`: 0,
    top: '20px',
    borderRadius: '4px',
    '&:hover': {
        backgroundColor: theme.palette.text.primary
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.5rem',
        color: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.25rem'
        },
        right: isDrawerOpen? `calc(${(window.innerWidth * 0.29) + 'px'})`:0
    },
    '@media (max-width: 768px)': {
        right: isDrawerOpen ? `calc(${(window.innerWidth * 0.336) + 'px'})` : 0,
    },
}))
export const StyledHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const LogoContainer = styled(Box)`
    position: relative;
`